import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <b>Hello</b> from <b>www.guildlessoutcasts.com</b>.
        </p>
      </header>
    </div>
  );
}

export default App;
